
.p-accordion-header-text {
    flex: 1 !important
}
.p-accordion-header-link {
    padding: 5px !important
}
.p-multiselect-item {
    padding: 2px !important
}

.avatar {
    height: 35px;
    width: 35px;
    min-width: 35px;
    background-color: var(--color-primary);
    border-radius: 50%;
}

.label-file {
    margin: auto;
    color: #000000;
    border: solid 1px black;
    border-radius: 10px;
    padding: 10px;

}
.label-file:hover{
    cursor: pointer;
    background-color: #f3f3f3;

}


// et on masque le input
.input-file {
    display: none;
}